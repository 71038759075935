import axios from 'axios'
import store from '@/store'
//import router from '../router'
/** @type {BaseServiceConector} */
export default class BaseServiceConector {
  constructor(api) {
    this.api = `${process.env.VUE_APP_ROOT_API_CONECTOR}/${api}`
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${store.state.token}`
    this.axios = axios
  }

  async testConnection() {
    return this.axios.get(
      `${process.env.VUE_APP_ROOT_API_CONECTOR}/conector/api/testConnection`,
    )
  }

  async importacaoSAT() {
    return this.axios.get(`${this.api}`, {})
  }
  async importacaoORI() {
    return this.axios.get(`${this.api}`, {})
  }

  async importacaoTranslogic() {
    return this.axios.get(`${this.api}`, {})
  }

  async importacaoKmmTos(obj) {
    return this.axios.post(`${this.api}`, obj)
  }

  async importacaoKmmGum(obj) {
    return this.axios.post(`${this.api}`, obj)
  }

  async importacaoAD(obj) {
    return this.axios.post(`${this.api}`, obj)
  }
  async importacaoAriba(obj) {
    return this.axios.post(`${this.api}`, obj)
  }
  /**
   * Listar todos os itens
   */
  find(obj) {
    //
    if (obj) {
      const query = Object.keys(obj)
        .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
        .join('&')
      return this.axios.get(`${this.api}?${query}`)
    } else {
      return this.axios.get(`${this.api}`)
    }
  }
  create(obj) {
    return this.axios.post(`${this.api}`, obj)
  }
}
