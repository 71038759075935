import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
import LayoutRatificacaoGestor from '@/layouts/LayoutRatificacaoGestor.vue'
import NotFound from '@/views/NotFound.vue'
import jsonFuncionalidade from '@/models/Funcionalidade.json'
import jsonCargo from '@/models/Cargo.json'
import jsonPerfil from '@/models/Perfil.json'
import jsonModulo from '@/models/Modulo.json'
import jsonSetor from '@/models/Setor.json'
import jsonFuncSistema from '@/models/FuncSistema.json'
import jsonLogs from '@/models/Logs.json'
import jsonPerfilFuncionalidade from '@/models/PerfilFuncionalidade.json'
import jsonStatus from '@/models/Status.json'
import jsonTipoUsuario from '@/models/TipoUsuario.json'
import jsonUsuarioFuncionalidade from '@/models/UsuarioFuncionalidade.json'
import jsonUsuarioPerfil from '@/models/UsuarioPerfil.json'

const routes = [
  {
    path: '/:catchAll(.*)',
    component: DefaultLayout,
    children: [
      {
        path: '/:catchAll(.*)',
        name: 'Error',
        component: NotFound,
        meta: {
          title: 'Error',
        },
      },
    ],
  },
  {
    path: '/notPermissionGestor',
    component: LayoutRatificacaoGestor,
    children: [
      {
        path: '/notPermissionGestor',
        name: 'Sem Permissão de Acesso ao Sistema',
        component: () => import('@/views/NotPermission.vue'),
      },
    ],
  },
  {
    path: '/rafificacao',
    name: 'Ratificação',
    component: LayoutRatificacaoGestor,
    redirect: '/',
    children: [
      {
        path: '/processos-gestor',
        name: 'Processos em Aberto Gestor',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/ProcessosList/ProcessosGestor.vue'
          ),
      },
      {
        path: '/ratificar-gestor',
        name: 'Gestor',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoGestorList.vue'
          ),
      },
      {
        path: '/ratificar-process',
        name: 'Process',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoProcessList.vue'
          ),
      },
      {
        path: '/ratificar-system',
        name: 'System',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoSystemList.vue'
          ),
      },
      {
        path: '/ratificar-system/:processo_id/:usuario_cs_tr',
        name: 'Ratificação System',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoSystemList.vue'
          ),
      },
      {
        path: '/ratificar-gestor/:processo_id/:usuario_cs_tr',
        name: 'Ratificação Gestor',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoGestorList.vue'
          ),
      },
      {
        path: '/ratificar-process/:processo_id/:usuario_cs_tr',
        name: 'Ratificação Process',
        component: () =>
          import(
            '@/views/ratificacao/processo_usuario/RatificacaoProcessList.vue'
          ),
      },
    ],
  },
  {
    path: '/',
    name: 'Início',
    component: DefaultLayout,
    redirect: '/usuario',
    children: [
      {
        path: '/revogacao-usuario',
        name: 'Revogação Usuário',
        meta: {},
        component: () => import('@/views/revogacao/List.vue'),
      },
      {
        path: '/revogacoes-pendentes-rpa',
        name: 'Revogações RPA',
        meta: {},
        component: () => import('@/views/revogacao/rpa/List.vue'),
      },
      {
        path: '/funcionalidade',
        name: 'Funcionalidade',
        meta: jsonFuncionalidade,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/cargo',
        name: 'Cargo',
        meta: jsonCargo,
        component: () => import('@/views/cargo/List.vue'),
      },
      {
        path: '/novo-cargo',
        name: 'Cadastro Cargo',
        meta: {},
        component: () => import('@/views/cargo/Form.vue'),
      },
      {
        path: '/cargo/:id',
        name: 'Alteração Cargo',
        meta: {},
        component: () => import('@/views/cargo/Form.vue'),
      },
      {
        path: '/cargo-gestor/:id',
        name: 'CargoGestor',
        meta: {},
        component: () => import('@/views/cargo/ListCargoGestor.vue'),
      },
      {
        path: '/funcSistema',
        name: 'FuncSistema',
        meta: jsonFuncSistema,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/logs',
        name: 'Logs',
        meta: jsonLogs,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/unidade',
        name: 'Unidade',
        meta: {},
        component: () => import('@/views/unidade/List.vue'),
      },
      {
        path: '/novaunidade',
        name: 'Cadastro Unidade',
        meta: {},
        component: () => import('@/views/unidade/Form.vue'),
      },
      {
        path: '/unidade/:id',
        name: 'Alteração Unidade',
        meta: {},
        component: () => import('@/views/unidade/Form.vue'),
      },
      {
        path: '/modulo',
        name: 'Modulo',
        meta: jsonModulo,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        meta: jsonPerfil,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/perfilfuncionalidade',
        name: 'PerfilFuncionalidade',
        meta: jsonPerfilFuncionalidade,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/setor',
        name: 'Setor',
        meta: jsonSetor,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/sistema',
        name: 'Sistema',
        meta: {},
        component: () => import('@/views/sistema/List.vue'),
      },
      {
        path: '/novo-sistema',
        name: 'Cadastro Sistema',
        meta: {},
        component: () => import('@/views/sistema/Form.vue'),
      },
      {
        path: '/sistema/:id',
        name: 'Alteração Sistema',
        meta: {},
        component: () => import('@/views/sistema/Form.vue'),
      },
      {
        path: '/status',
        name: 'Status',
        meta: jsonStatus,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/status',
        name: 'Status',
        meta: jsonStatus,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/tipousuario',
        name: 'TipoUsuario',
        meta: jsonTipoUsuario,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/usuario',
        name: 'Usuario',
        meta: {},
        component: () => import('@/views/usuario/List.vue'),
      },
      {
        path: '/novo-usuario',
        name: 'Cadastro Usuario',
        meta: {},
        component: () => import('@/views/usuario/Form.vue'),
      },
      {
        path: '/usuario/:id',
        name: 'Alteração Usuario',
        meta: {},
        component: () => import('@/views/usuario/Form.vue'),
      },
      {
        path: '/direitos',
        name: 'Direitos',
        meta: {},
        component: () => import('@/views/direitos/List.vue'),
      },
      {
        path: '/usuariofuncionalidade',
        name: 'UsuarioFuncionalidade',
        meta: jsonUsuarioFuncionalidade,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/usuarioperfil',
        name: 'UsuarioPerfil',
        meta: jsonUsuarioPerfil,
        component: () => import('@/views/default/List.vue'),
      },
      {
        path: '/logsIntegrador',
        name: 'Logs Integrador',
        component: () => import('@/views/logs/integrador/List.vue'),
      },
      {
        path: '/logSistemas',
        name: 'Logs Sistemas',
        component: () => import('@/views/logs/sistemas/List.vue'),
      },
      {
        path: '/logsTransferidos',
        name: 'Transferidos',
        component: () => import('@/views/logs/transferidos/List.vue'),
      },
      {
        path: '/logsImportacao',
        name: 'Importação',
        component: () => import('@/views/logs/logs_importacao/List.vue'),
      },
      {
        path: '/logsDesligados',
        name: 'Desligados',
        component: () => import('@/views/logs/desligados/List.vue'),
      },
      {
        path: '/logsRevogacao',
        name: 'Logs Revogação',
        component: () => import('@/views/logs/revogacao/List.vue'),
      },
      {
        path: '/notPermission',
        name: 'Sem Permissão de Acesso',
        component: () => import('@/views/NotPermission.vue'),
      },
      {
        path: '/importacaoUser',
        name: 'Importações Usuário',
        component: () => import('@/views/importacao/ListUser.vue'),
      },
      {
        path: '/importacaoSIV',
        name: 'Importações SIV',
        component: () => import('@/views/importacao/ListSIV.vue'),
      },
      {
        path: '/importacaoTL',
        name: 'Importações TRANSLOGIC',
        component: () => import('@/views/importacao/ListTL.vue'),
      },
      {
        path: '/importacaoORI',
        name: 'Importações ORI',
        component: () => import('@/views/importacao/ListORI.vue'),
      },
      {
        path: '/importacaoBPM',
        name: 'Importações BPM',
        component: () => import('@/views/importacao/ListBPM.vue'),
      },
      {
        path: '/importacaoARIBA',
        name: 'Importações ARIBA',
        component: () => import('@/views/importacao/ListARIBA.vue'),
      },
      {
        path: '/importacaoSAT',
        name: 'Importações SAT',
        component: () => import('@/views/importacao/ListSAT.vue'),
      },
      {
        path: '/importacaoKMMTOS',
        name: 'Importações KMM-TOS',
        component: () => import('@/views/importacao/ListKMMTOS.vue'),
      },
      {
        path: '/importacaoKMMGUM',
        name: 'Importações KMM-GUM',
        component: () => import('@/views/importacao/ListKMMGUM.vue'),
      },
      {
        path: '/importacaoAD',
        name: 'Importações AD',
        component: () => import('@/views/importacao/ListAD.vue'),
      },
      {
        path: '/importacaoSAPRH',
        name: 'Importações SAPRH',
        component: () => import('@/views/importacao/ListSAPRH.vue'),
      },
      {
        path: '/processos',
        name: 'Processos',
        component: () => import('@/views/ratificacao/processo/List.vue'),
      },
      {
        path: '/processo/:id',
        name: 'Alterar Processo',
        component: () => import('@/views/ratificacao/processo/Dashboard.vue'),
      },
      {
        path: '/transferencia',
        name: 'Transferência',
        component: () => import('@/views/transferencia/List.vue'),
      },
      {
        path: '/list_cards',
        name: 'Listagem de Processos Por Status',
        component: () => import('@/views/ratificacao/list_cards/Dashboard.vue'),
      },
      {
        path: '/desligamento',
        name: 'Desligamento',
        component: () => import('@/views/desligamento/List.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/email',
        name: 'Email',
        component: () => import('@/views/email/Emails.vue'),
      },
      {
        path: '/dashboard-usuario',
        name: 'Dashboard Usuario',
        component: () => import('@/views/dashboard-usuario/List.vue'),
      },
      {
        path: '/linha-base',
        name: 'Linha Base',
        component: () => import('@/views/processo_importacao/List.vue'),
      },
      {
        path: '/agendamentos',
        name: 'Listagem Agendamentos',
        component: () => import('@/views/agendamento/List.vue'),
      },
      {
        path: '/lastLogin',
        name: 'Last Login',
        component: () => import('@/views/last_login/List.vue'),
      },
      {
        path: '/simulacaodesligamento',
        name: 'Simulacao Desligamento',
        component: () => import('@/views/simulacao/Desligamento.vue'),
      },
      {
        path: '/simulacaotransferencia',
        name: 'Simulacao Transferencia',
        component: () => import('@/views/simulacao/Transferencia.vue'),
      },
      {
        path: '/parametros-sla',
        name: 'SLA',
        component: () => import('@/views/parametros/List.vue'),
      },
      {
        path: '/teste-siv-tl',
        name: 'TESTE SIV/TL',
        component: () => import('@/views/teste/script_siv_tl.vue'),
      },
    ],
  },
]
import { useKeycloak, isTokenReady } from '@baloise/vue-keycloak'
import store from '@/store'
async function setToken() {
  await isTokenReady()
  var t = await useKeycloak()
  if (t.token.value) {
    store.commit({
      type: 'setToken',
      value: t.token.value,
    })
  }
  return
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  await setToken()
  if (store.state.token) {
    if (to.fullPath.includes('state')) {
      const rota = to.fullPath.split('&state')
      next(rota[0])
    } else {
      next()
    }
  } else {
    if (to.fullPath.includes('state')) {
      const rota = to.fullPath.split('&state')
      next(rota[0])
    } else {
      next()
    }
  }
})

export default router
